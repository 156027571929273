.card, .area {
  font-family: 'Computer Modern Typewriter';
  font-size: 18px;
}

.area {
  background: rgba(255, 0, 0, .05);
  border: none;
  padding: 20px;
  highlight: blue;
}


.area:focus {
  outline: 1px dotted black;;
}

.isLoading {
  .image,
  h2,
  textarea,
  p {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 0;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
  }

  .image {
    height: 200px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  h2 {
    height: 30px;
  }

  p {
    height: 70px;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}
