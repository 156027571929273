.interaction {
  white-space: pre-line;
}

.user {
  font-family: 'Computer Modern Typewriter';
  text-align: right;
}

.agent .text {
}

.text {
  padding: 20px;
}

.seeAlso {
  text-align: right;
  color: gray;
}


.ref[data-tooltip]::before {
  content: attr(data-tooltip);
  position: absolute;
  background: black;
  color: white;
  padding: 0.25rem;
  border-radius: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ref[data-tooltip]:hover::before {
  opacity: 1;
  transform: translate(0, -0.5rem);
}
